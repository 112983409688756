



    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        name: "detailCompare",
        components: {
            layout: () => import('@/components/layouts/CompareLayout.vue')
        },
        mixins:[]
    })
    export default class detailCompare extends Vue {
        public created():void {
            // on componenet created
        }
        public mounted():void {
            // on componenet mounted
        }
    }
